import { Button, Col, Form, Image, Row, Select, Space, Typography, theme } from 'antd'
import React, { useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiOutlineClose } from 'react-icons/ai'
import getStyles from './styles'

type CustomFile = File & {
  preview: string
}

export default function DetailPeopleImagesFormSection({ name }: { name: number }) {
  const { token } = theme.useToken()
  const styles = getStyles(token)
  const [files, setFiles] = useState<CustomFile[]>([])
  const formInstance = Form.useFormInstance()

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
      },

      onDropAccepted: (acceptedFiles: File[]) => {
        setFiles((prev) => [
          ...prev,
          ...acceptedFiles.map((file, index) => {
            const imageReader = new FileReader()

            imageReader.onloadend = () => {
              formInstance.setFieldValue(
                ['people', name, 'images', files.length ? files.length : index, 'uri'],
                imageReader.result
              )
            }
            imageReader.readAsDataURL(file)
            return {
              ...file,
              preview: URL.createObjectURL(file),
            }
          }),
        ])
      },
    })

  const dndstyle = useMemo(
    () => ({
      ...styles.dndbaseStyle,
      ...(isFocused ? styles.dndfocusedStyle : {}),
      ...(isDragAccept ? styles.dndacceptStyle : {}),
      ...(isDragReject ? styles.dndrejectStyle : {}),
    }),
    // eslint-disable-next-line
    [isFocused, isDragAccept, isDragReject]
  )

  const onHandleRemove = (indexFile: number) => {
    setFiles((prev) => {
      const resultFiles = prev
        .filter((f, index) => index !== indexFile)
        .map((f, index) => {
          const resultImageForm = formInstance.getFieldValue(['people', name, 'images', index])
          return { ...f, ...resultImageForm }
        })
      formInstance.setFieldValue(['people', name, 'images'], resultFiles)
      return resultFiles
    })
  }

  const previews = files.map((file, index) => (
    <Col key={name.toString().concat(index.toString())} span={12} style={styles.previewRoot}>
      <Row style={styles.previewContainer} gutter={12}>
        <Col span={22}>
          <div style={styles.previewContent}>
            <Form.Item name={[name, 'images', index, 'uri']} style={styles.previewImageContainer}>
              <Image src={file.preview} style={styles.previewImage as React.CSSProperties} />
            </Form.Item>
            <div style={styles.previewTypeContainer}>
              <Typography.Text
                style={styles.previewTypeLabel as React.CSSProperties}
                type="secondary"
              >
                Type
              </Typography.Text>
              <Form.Item
                name={[name, 'images', index, 'type']}
                rules={[{ required: true, message: 'Plese select a type' }]}
                style={styles.formItemContainerInputType}
              >
                <Select
                  size="small"
                  bordered={false}
                  options={[
                    { value: 'identification', label: 'ID' },
                    { value: 'person', label: 'Person' },
                  ]}
                />
              </Form.Item>
            </div>
          </div>
        </Col>
        <Col span={2}>
          <Button
            style={styles.previewDeleteButton}
            size="small"
            shape="circle"
            icon={<AiOutlineClose style={styles.previewDeleteButtonIcon} />}
            onClick={() => onHandleRemove(index)}
          />
        </Col>
      </Row>
    </Col>
  ))

  return (
    <>
      <Row style={styles.root} gutter={24}>
        {previews}
      </Row>
      <Space {...getRootProps({ style: dndstyle as React.CSSProperties })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography.Text type="secondary">Drop the files here to upload</Typography.Text>
        ) : (
          <Typography.Text type="secondary">Click or drag files here to upload</Typography.Text>
        )}
      </Space>
    </>
  )
}
