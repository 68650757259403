import { CSSProperties } from 'react'

const ErrorNotificationStyles = (): Record<string, CSSProperties> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default ErrorNotificationStyles
