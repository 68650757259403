import React, { useMemo, useState } from 'react'
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  theme,
  Typography,
} from 'antd'
import { FaUserTimes } from 'react-icons/fa'
import { BsPeopleFill } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'
import countries from '../../../../../../../../../data/countries.json'

import DetailPeopleImagesFormSection from '../images'
import { INPUT_DATA_GENDERS } from '../../../../../../../../../constants'
import getStyles from './styles'
import DetailPeopleFormAdress from '../address'
import AliasesTag from './components/tag'
import DetailIdentificationPeopleFormSection from './components/identification'
import DetailPersonTypePeopleFormSection from './components/personType'
import { applyMaskValue } from '../../../../../../../../../utils'

export default function DetailPeopleFormSection({
  index,
  remove,
  name,
  restField,
  isAdvancedSearch,
}: {
  index: number
  remove: (index: number | number[]) => void
  name: number
  restField: {
    fieldKey?: number | undefined
  }
  isAdvancedSearch?: boolean
}) {
  const { token } = theme.useToken()
  const styles = getStyles(token)
  const [type, setType] = useState('offender')
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const OFFENDER_LABEL = 'offender'

  const formInstance = Form.useFormInstance()
  const suffixPhoneNumber = Form.useWatch(['people', name, 'phoneNumberSuffix'], formInstance)
  const selectedCountry = useMemo(
    () => countries.find((country) => country.country_code === (suffixPhoneNumber ?? '+61')),
    [suffixPhoneNumber]
  )

  const renderIconType = () => {
    if (type) {
      if (type === OFFENDER_LABEL) {
        return <FaUserTimes style={styles.panelIcon} />
      }
      return <BsPeopleFill style={styles.panelIcon} />
    }
    return null
  }

  const suffixSelector = useMemo(
    () => (
      <Form.Item noStyle name={[name, 'phoneNumberSuffix']} initialValue="+61">
        <Select
          style={{ width: 130 }}
          showSearch
          optionFilterProp="label"
          options={countries.map((country) => ({
            label: `${country.flag} ${country.name}`,
            value: country.country_code,
          }))}
        />
      </Form.Item>
    ),
    [name]
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\D/g, '')

    const maskedValue = applyMaskValue(inputValue, selectedCountry?.mask)

    formInstance.setFieldValue(['people', name, 'phone_number'], maskedValue)
  }

  return (
    <Collapse ghost style={styles.root} defaultActiveKey={[index]}>
      <Collapse.Panel
        key={index}
        header={
          <Space style={styles.collpasePanelheader}>
            {!isAdvancedSearch && renderIconType()}
            <Typography.Text>
              {!isAdvancedSearch && (
                <Typography.Text type="secondary">
                  {type === OFFENDER_LABEL ? 'Offender' : 'Associate'}
                  {givenName || familyName ? ':' : null}
                </Typography.Text>
              )}{' '}
              {givenName.concat(` ${familyName}`)}
            </Typography.Text>
          </Space>
        }
        extra={
          index !== 0 ? (
            <Button
              style={styles.collapsePanelExtraButton}
              size="small"
              onClick={(event) => {
                event.stopPropagation()
                remove(name)
              }}
              shape="circle"
              icon={<AiOutlineClose {...styles.collapsePanelExtraButtonIcon} />}
            />
          ) : null
        }
      >
        <Row justify="space-between" wrap gutter={12}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="First Name"
              {...restField}
              name={[name, 'given_name']}
              rules={[
                {
                  required: type === OFFENDER_LABEL,
                },
              ]}
            >
              <Input onChange={(event) => setGivenName(event.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Last Name"
              {...restField}
              name={[name, 'family_name']}
              rules={[
                {
                  required: type === OFFENDER_LABEL,
                },
              ]}
            >
              <Input onChange={(event) => setFamilyName(event.target.value)} />
            </Form.Item>
          </Col>
        </Row>
        {!isAdvancedSearch && (
          <>
            <DetailPersonTypePeopleFormSection name={name} type={type} setType={setType} />
            <Form.Item name={[name, 'aliases']} label="Aliases and nicknames">
              <Select
                mode="tags"
                showSearch={false}
                notFoundContent={
                  <Row style={styles.formItemSelectNotFoundContainer}>
                    <Typography.Paragraph type="secondary">
                      {`You add multiple values here, just type it and press 'Enter'`}
                    </Typography.Paragraph>
                  </Row>
                }
                maxTagCount="responsive"
                tagRender={({ label, closable, onClose }) => (
                  <AliasesTag
                    renderClosable={closable}
                    renderLabel={label}
                    renderOnClose={onClose}
                  />
                )}
              />
            </Form.Item>
          </>
        )}
        <Row justify="space-between" wrap gutter={12}>
          <Col xs={24} sm={12}>
            <Form.Item name={[name, 'birth_date']} label="Date of birth">
              <DatePicker format="DD/MM/YYYY" style={styles.formItemDatePickerContainer} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={[name, 'gender']} label="Gender">
              <Select options={INPUT_DATA_GENDERS} />
            </Form.Item>
          </Col>
        </Row>
        <DetailIdentificationPeopleFormSection name={name} />
        <DetailPeopleFormAdress name={name} />
        <Row justify="space-between" wrap>
          <Col xs={24}>
            <Form.Item
              label="Phone number"
              name={[name, 'phone_number']}
              rules={[{ required: true }, { min: 9, max: 12 }]}
            >
              <Input
                placeholder={`Enter phone number ${selectedCountry?.mask}`}
                addonBefore={suffixSelector}
                prefix={suffixPhoneNumber}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        {!isAdvancedSearch && (
          <Form.Item label="Images of this person">
            <DetailPeopleImagesFormSection name={name} />
          </Form.Item>
        )}
      </Collapse.Panel>
    </Collapse>
  )
}

DetailPeopleFormSection.defaultProps = {
  isAdvancedSearch: false,
}
