import React, { useEffect, useMemo, useState } from 'react'
import { Typography } from 'antd'
import { isAxiosError } from 'axios'
import buildSupportEmail from './ErrorNotification.utils'
import ErrorNotificationStyles from './ErrorNotification.style'

export default function ErrorNotification({ error }: { error: Error }) {
  const styles = ErrorNotificationStyles()
  const [notificationError, setNotificationError] = useState({
    message: error.message,
    status: '',
    error: [],
  })

  useEffect(() => {
    if (isAxiosError(error)) {
      const { response } = error
      if (response) {
        const { message, status, error: errorData } = response.data
        setNotificationError({ message, status, error: errorData })
      }
    }
  }, [error])

  const emailHref = useMemo(() => buildSupportEmail(notificationError), [notificationError])

  return (
    <div style={styles.container}>
      <Typography>
        {`${notificationError.message}. `}
        <Typography.Text type="secondary">{notificationError.status}</Typography.Text>
      </Typography>
      <br />
      <Typography.Paragraph type="secondary">
        If the error persists, please <a href={emailHref}>contact</a> our support team.
      </Typography.Paragraph>
    </div>
  )
}
