import { ErrorT } from '../../types/errorT'

const buildSupportEmail = (error: ErrorT) => {
  const subject = `Good To Book error report | ${error.status} | ${error.message}`
  const body = `Technical Details: ${error.error?.map(
    (errorDetailItem) => `${errorDetailItem.message}`
  )}
  `
  return `mailto:api-support@goodtobook.com?subject=${subject}&body=${body}`
}

export default buildSupportEmail
